import { Box, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import type { PlanType, Plan } from '.';
import { PlanButton } from './PlanButton';
import { PriceItem } from './PriceItem';
import Markdown from 'react-markdown';
import styles from './styles.module.css';
import { plansColors } from './plansColors';
import { StyledTooltip } from './StyledTooltip';

interface Props {
  isRecommended: boolean;
  isLoading: boolean;
  isProcessing?: boolean;
  isSelected?: boolean;
  specialOffer?: string;
  plan: PlanType;
  title: string;
  description: string;
  features: Plan['list'];
  category: string;
  price: string;
  offerPrice: string;
  onPlanSelection: () => void;
}

function RecommendedBadge() {
  return (
    <Box position="absolute" zIndex="1" width="calc(100% - 12px)" top="-4px" textAlign="center">
      <span
        style={{
          backgroundColor: '#F5D400',
          padding: '2px 12px 2px 12px',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          textTransform: 'uppercase',
          fontSize: '9px'
        }}
      >
        Recommended
      </span>
    </Box>
  );
}

const CardWrapper = styled(Box)({
  backgroundColor: '#fff',
  padding: '1rem',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  '&:hover': {
    cursor: 'cursor'
  },
  overflow: 'hidden'
});

const PlanTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 28,
  fontFamily: 'Open sans, sans-serif',
  lineHeight: '38px',
  color: '#414042',
  '@media only screen and (max-width: 320px)': {
    fontSize: 26
  }
});

const CurrentPlan = styled(Typography)({
  width: '100%',
  height: 10,
  fontSize: 14,
  letterSpacing: '0px',
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '24px',
  textAlign: 'center',
  color: 'var(--color-plan)'
});

const PlanDescription = styled(Typography)({
  fontSize: 15,
  fontFamily: 'Open sans, sans-serif',
  color: '#414042',
  textAlign: 'center',
  marginTop: 8,
  minHeight: 64
});

const PlanFeatureAllow = styled(ListItemText)({
  padding: 0,
  margin: 0,
  marginBottom: 8,
  '& span, & p': {
    fontSize: 14,
    fontFamily: 'Open sans, sans-serif',
    color: '#414042',
    textAlign: 'center'
  },
  '& p': {
    margin: 0
  },
  '&[data-tooltip]:hover': {
    textDecoration: 'underline'
  }
});

export function PlanCard({
  isRecommended,
  isLoading,
  plan,
  title,
  description,
  features,
  category,
  isProcessing,
  isSelected,
  specialOffer,
  price,
  offerPrice,
  onPlanSelection
}: Props) {
  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        width={268}
        height={460}
        style={{ borderRadius: 8 }}
      />
    );
  }

  const {
    primary: planColor,
    accent: planColorAccent,
    hover: planColorHover,
    font: planColorFont,
    link: planColorLink = 'inherit'
  } = plansColors[plan];
  const cssVars = {
    '--color-from': planColorAccent,
    '--color-to': planColor,
    '--color-plan': planColor,
    '--color-bg': planColor,
    '--color-hover': planColorHover,
    '--color-font': planColorFont,
    '--color-link': planColorLink
  } as React.CSSProperties;

  return (
    <Box style={cssVars} height="100%" position="relative" paddingTop="16px">
      {isRecommended ? <RecommendedBadge /> : null}
      <div className={styles.cardBorder}>
        <CardWrapper>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0px 12px"
            style={{ gap: '1.4rem' }}
          >
            <PriceItem
              plan={plan}
              specialOffer={specialOffer}
              price={price}
              offerPrice={offerPrice}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              position="relative"
            >
              <PlanTitle>{title}</PlanTitle>
              <PlanDescription>{description}</PlanDescription>
            </Box>
            <Box padding="0px 24px" width="100%">
              <PlanButton
                onClick={onPlanSelection}
                data-type="btn-click"
                data-name={
                  category === 'widget'
                    ? `btn-plan-type-premium-${plan}`
                    : `p-${plan.toLowerCase()}-btn`
                }
                disabled={isProcessing || isSelected}
              >
                {isProcessing ? (
                  'Loading...'
                ) : (
                  <span data-name={`btn-plan-type-premium-${plan}`}>Start now</span>
                )}
              </PlanButton>
            </Box>
            <List dense={true} disablePadding={true}>
              {features.map(item => {
                let content = (
                  <PlanFeatureAllow
                    data-tooltip={item.description ? true : undefined}
                    primary={
                      <Markdown linkTarget="_blank" className={styles.markdown}>
                        {item.value}
                      </Markdown>
                    }
                  />
                );
                if (item.description) {
                  content = (
                    <StyledTooltip
                      style={cssVars}
                      arrow={true}
                      interactive={true}
                      enterTouchDelay={0}
                      title={
                        <Markdown linkTarget="_blank" className={styles.markdownTooltip}>
                          {item.description}
                        </Markdown>
                      }
                    >
                      {content}
                    </StyledTooltip>
                  );
                }
                return (
                  <ListItem key={item.id} disableGutters={true}>
                    {content}
                  </ListItem>
                );
              })}
            </List>
          </Box>
          {isSelected ? (
            <Box padding="0px 24px" width="100%">
              <CurrentPlan>(Current Plan)</CurrentPlan>
            </Box>
          ) : null}
        </CardWrapper>
      </div>
    </Box>
  );
}
