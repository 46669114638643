import { Typography, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Router from 'next/router';
import React, { useMemo } from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';
import { isFunction } from '../../helpers/javascript';
import Dialog from '../Dialog';
import { PlansSwitch } from './PlansSwitch';
import { WidgetsPlanHeader } from './WidgetsPlanHeader';
import { VideosPlanHeader } from './VideosPlanHeader';
import { PlansToggle } from './PlansToggle';
import { PlanCard } from './PlanCard';
import { styled } from '@material-ui/styles';
import useRegisterPlans from '../../hooks/useRegisterPlans';

function roundPrice(price: number) {
  return (Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2);
}

const Wrapper = styled(Box)({
  marginTop: 26,
  justifyContent: 'center',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',
  columnGap: '6px',
  rowGap: '16px',
  padding: '16px 6px',
  justifySelf: 'center'
});

const PlansInfo = styled(Typography)({
  fontSize: 16,
  letterSpacing: '0px',
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '36px',
  '@media only screen and (max-width: 425px)': {
    fontSize: 12,
    lineHeight: '24px'
  },
  textAlign: 'center',
  marginBottom: 40,
  marginTop: 24
});

function PlanCards(props: PlanCardsProps) {
  if (props.plans == null) {
    return <PlanCardsWithoutData {...props} />;
  }
  return <PlanCardsWithData {...props} />;
}

function PlanCardsWithoutData(props: PlanCardsProps) {
  const videoPlans = useRegisterPlans({ category: 'video' });
  const widgetPlans = useRegisterPlans({ category: 'widget' });
  const plans = useMemo(
    () => ({ widget: widgetPlans, video: videoPlans }),
    [widgetPlans, videoPlans]
  );
  return <PlanCardsWithData {...props} plans={plans} />;
}

const PlanCardsWithData = ({
  plans,
  redirectUrl = '/instagram-widget',
  onClick,
  loadingCard = false,
  allowFreeClick = false,
  showRecommended = true,
  showFree = true,
  defaultCategory = 'video',
  showInfo = false,
  onChangeCategory = () => {},
  showCategorySelector = false
}: PlanCardsProps) => {
  const { plan: currentPlan } = useAuthentication();
  const customPlanRef = React.useRef<any>(null);
  const [category, setCategory] = React.useState<PlanCategory>(defaultCategory);
  const [billingPeriod, setBillingPeriod] = React.useState<'month' | 'year'>('year');

  const handleClick = (item: any) => {
    if (currentPlan && currentPlan.isCustom) customPlanRef.current.show();
    else if (isFunction(onClick) && (item.price !== 0 || (allowFreeClick && item.price === 0))) {
      onClick(item);
    } else {
      Router.push(redirectUrl);
    }
  };

  const plansToShow = React.useMemo(() => {
    if (!plans || !plans[category]) return [];
    return plans[category].filter((p: any) => {
      if (p.price === 0 && !showFree) return false;
      return p.interval === billingPeriod || p.interval == null;
    });
  }, [billingPeriod, category, plans, showFree]);

  const handleCategory = (newCategory: PlanCategory) => {
    if (newCategory !== null) {
      setCategory(newCategory);
      onChangeCategory(newCategory);
    }
  };

  return (
    <Box maxWidth={plansToShow.length > 2 ? '1300px' : '900px'} width="100%" margin="auto">
      {showCategorySelector && <PlansToggle onChange={handleCategory} category={category} />}
      {showInfo && category === 'widget' && <WidgetsPlanHeader />}
      {showInfo && category === 'video' && <VideosPlanHeader />}

      <PlansSwitch
        billingPeriod={billingPeriod}
        onChange={setBillingPeriod}
        discount={category === 'widget' ? '-18%' : '-25%'}
      />
      <Wrapper>
        {plansToShow.map(plan => {
          let price = roundPrice(billingPeriod === 'year' ? plan.price / 12 : plan.price);
          let offerPrice = '0';
          const isSpecialOffer =
            plan.type !== 'Free' && plan.specialOffer && plan.specialOffer.active;
          if (isSpecialOffer && plan.specialOffer) {
            offerPrice = roundPrice(
              billingPeriod === 'year' ? plan.specialOffer.price / 12 : plan.specialOffer.price
            );
          }

          return (
            <PlanCard
              key={plan.id}
              isRecommended={plan.type === 'Pro' && category === 'widget' && showRecommended}
              isLoading={false}
              isProcessing={loadingCard}
              isSelected={currentPlan && currentPlan.id === plan.id}
              specialOffer={isSpecialOffer ? plan.specialOffer?.reason : undefined}
              plan={plan.type}
              title={plan.title}
              description={plan.description}
              features={plan.list ?? []}
              category={category}
              price={price}
              offerPrice={offerPrice}
              onPlanSelection={() => handleClick(plan)}
            />
          );
        })}
        <Dialog forwardRef={customPlanRef} useClose>
          <Box marginTop="36px" marginBottom="36px">
            You are using a custom plan. Please, contact the support team to manage it!
          </Box>
        </Dialog>
      </Wrapper>

      <Container maxWidth="lg">
        <PlansInfo><b>30-Day</b> Money-Back Guarantee on All Premium Plans.</PlansInfo>
      </Container>
    </Box>
  );
};

export type PlanCategory = 'video' | 'widget';
export type PlanType = 'Free' | 'Basic' | 'Pro' | 'Business';
type Plans = Record<PlanCategory, Plan[]>;

type Feature = {
  id: string;
  value: string;
  description?: string;
};

export interface Plan {
  id: string;
  price: number;
  type: PlanType;
  title: string;
  specialOffer?: {
    active: boolean;
    price: number;
    reason: string;
  };
  description: string;
  list: Feature[];
}

interface PlanCardsProps {
  plans?: Plans;
  redirectUrl?: string;
  onClick: (plan: Plan) => void;
  loadingCard?: boolean;
  allowFreeClick?: boolean;
  showFree?: boolean;
  showRecommended?: boolean;
  defaultCategory?: PlanCategory;
  showInfo?: boolean;
  onChangeCategory?: any;
  showCategorySelector?: boolean;
}

export default PlanCards;
