import * as React from 'react';
import { isArray } from '../helpers/javascript';
import { logged, getGoogleSheetToken, checkUserSession } from '../services/security.service';
import { manageCart, plans } from '../services/stripe.services';
import { find as findNotifications } from '../services/notification.service';

type AuthState = Omit<AuthContextValue, 'dispatch'>;
const initialState: AuthState = {
  user: null,
  notifications: { data: [], count: 0 }
};

type ActionType =
  | { type: 'UPDATE_SHEET_TOKEN' }
  | { type: 'LOGIN' }
  | { type: 'NO-LOGIN' }
  | { type: 'LOGOUT' }
  | { type: 'UPDATE_PAYMENT'; plan: PlanInfo | false }
  | { type: 'CLEAR_NOTIFICATIONS' }
  | { type: 'PUSH_NOTIFICATIONS'; notifications: Notifications }
  | { type: 'REMOVE_NOTIFICATIONS'; notification: string }
  | { type: 'CHANGE_TEAM'; team: unknown }
  | { type: 'CHANGE_BRANDING'; branding: Object };

const authReducer = (state: AuthState, action: ActionType) => {
  switch (action.type) {
    case 'UPDATE_SHEET_TOKEN':
      return { ...state, token: getGoogleSheetToken() };
    case 'LOGIN':
      return { ...state, user: logged() };
    case 'NO-LOGIN':
      return { ...state, user: false };
    case 'LOGOUT':
      return { ...state, user: false, plan: false as const };
    case 'UPDATE_PAYMENT':
      return { ...state, plan: action.plan };
    case 'CLEAR_NOTIFICATIONS': {
      return { ...state, notifications: { data: [], count: 0 } };
    }
    case 'PUSH_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          data:
            state.notifications && isArray(state.notifications.data)
              ? state.notifications.data.concat(action.notifications.data)
              : action.notifications.data,
          count: action.notifications.count
        }
      };
    case 'REMOVE_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          data:
            state.notifications && isArray(state.notifications.data)
              ? state.notifications.data.filter(n => n._id !== action.notification)
              : [],
          count: state.notifications.count === 0 ? 0 : state.notifications.count - 1
        }
      };
    case 'CHANGE_TEAM':
      localStorage.setItem('user', JSON.stringify({ ...state.user, team: action.team }));
      return { ...state, user: { ...state.user, team: action.team } };
    case 'CHANGE_BRANDING':
      localStorage.setItem('user', JSON.stringify({ ...state.user, branding: action.branding }));
      return { ...state, user: { ...state.user, branding: action.branding } };
    default:
      throw new Error('Inexistent action');
  }
};

interface UserInfo {
  username: string;
  jwt: string;
}

interface PlanInfo {
  id: string;
  isCustom?: boolean;
 }

interface Notification {
  _id: string;
}

interface Notifications {
  data: Notification[];
  count: number;
}

interface AuthContextValue {
  user?: UserInfo | null;
  plan?: PlanInfo | false;
  notifications: Notifications;
  dispatch: React.Dispatch<ActionType>;
}

const AuthContext = React.createContext(initialState);

export function AuthProvider({
  children,
  stripeSuccess
}: React.PropsWithChildren<{ stripeSuccess?: string }>) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  const value = React.useMemo(
    () => ({
      user: state.user,
      plan: state.plan,
      notifications: state.notifications,
      dispatch
    }),
    [state.user, state.plan, state.notifications]
  );

  React.useEffect(() => {
    // FIXME: Use a more robust authentication model. i.e. server side.
    const isValidSession = checkUserSession();
    isValidSession.then(auth => {
      if (auth) {
        dispatch({ type: 'LOGIN' });
        plans()
          .then(response => {
            dispatch({ type: 'UPDATE_PAYMENT', plan: response });
          })
          .catch(() => {
            dispatch({ type: 'UPDATE_PAYMENT', plan: false });
          });
        if (stripeSuccess)
          manageCart({ status: stripeSuccess === 'false' ? 'active' : 'inactive' });
      } else {
        dispatch({ type: 'NO-LOGIN' });
        dispatch({ type: 'UPDATE_PAYMENT', plan: false });
      }
    });
  }, []);

  React.useEffect(() => {
    if (state.user)
      findNotifications().then(response => {
        dispatch({
          type: 'PUSH_NOTIFICATIONS',
          notifications: { data: response, count: response.length }
        });
      });
    else dispatch({ type: 'CLEAR_NOTIFICATIONS' });
  }, [state.user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthentication() {
  return React.useContext(AuthContext);
}
